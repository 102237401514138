import React from "react";
import { Link } from "gatsby";
import { FaLinkedin } from "react-icons/fa";

function Footer() {
  return (
    <footer>
      <div className='footer-content container-fluid'>
        <div>
          <div className='footer-info row'>
            <div className='social-info col-lg-4 col-md-4 col-sm-4 col-xs-12'>
              <img
                src={"/images/logo-white.png"}
                alt='Custiv Logo'
                className='footer-logo'
              />
              <p className='copy-right'>
                {" "}
                &copy; {new Date().getFullYear()} Custiv, All Rights Reserved{" "}
              </p>
              <p className='social-head'> For Requirements: </p>
              <p className='social-desc'>
                <a href='mailto:rfq@custiv.com'> rfq@custiv.com </a>
              </p>

              <p className='social-head'> For Support: </p>
              <p className='social-desc'>
                <a href='mailto:support@custiv.com'> support@custiv.com </a>
              </p>

              <p className='social-head'> For Partners: </p>
              <p className='social-desc'>
                <a href='mailto:partner@custiv.com'> partner@custiv.com </a>
              </p>

              <div className='social-channels'>
                <ul className='social-media'>
                  <li>
                    <a
                      href='https://in.linkedin.com/company/custiv'
                      target='_blank'>
                      <FaLinkedin />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-2 col-md-2 col-sm-2 col-xs-12'>
              <div className='footer-about col-lg-12 col-md-12 col-sm-12 col-xs-6'>
                <p className='footer-aboutHead'> Company </p>
                <p>
                  <Link className='footer-aboutDesc' to='/about-us'>
                    About Us
                  </Link>
                </p>
                <p>
                  <Link className='footer-aboutDesc' to='/contact-us'>
                    Contact Us
                  </Link>
                </p>
                <p>
                  <a className='footer-aboutDesc' href='/blog'>
                    Custiv Blog
                  </a>
                </p>
              </div>
              <div className='footer-explore col-lg-12 col-md-12 col-sm-12 col-xs-6'>
                <p className='footer-exploreHead'> Explore </p>
                <p>
                  <Link className='footer-exploreDesc' to='/quality-assurance'>
                    Quality Assurance
                  </Link>
                </p>
                <p>
                  <Link className='footer-exploreDesc' to='/partner-network'>
                    Partner Network
                  </Link>
                </p>
              </div>
            </div>
            <div className='footer-services col-lg-3 col-md-3 col-sm-3 col-xs-12'>
              <p className='footer-serviceHead'>Services </p>
              <div className='row serv-row'>
                <div className='column1 col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                  <p>
                    <Link
                      className='footer-serviceDesc'
                      to='/service/cnc-machining-services'>
                      CNC Machining
                    </Link>
                  </p>
                  <p>
                    <Link
                      className='footer-serviceDesc'
                      to='/service/prototyping-services'>
                      Prototyping
                    </Link>
                  </p>
                  <p>
                    <Link
                      className='footer-serviceDesc'
                      to='/service/metal-fabrication-services'>
                      Fabrication
                    </Link>
                  </p>
                  <p>
                    <Link
                      className='footer-serviceDesc'
                      to='/service/plastic-injection-molding-services'>
                      Plastic Injection Molding
                    </Link>
                  </p>
                </div>
                <div className='column2 col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                  <p>
                    <Link
                      className='footer-serviceDesc'
                      to='/service/casting-services'>
                      Casting
                    </Link>
                  </p>
                  <p>
                    <Link
                      className='footer-serviceDesc'
                      to='/service/metal-stamping-services'>
                      Stamping
                    </Link>
                  </p>
                  <p>
                    <Link
                      className='footer-serviceDesc'
                      to='/service/metal-forging-services'>
                      Forging
                    </Link>
                  </p>
                  <p>
                    <Link
                      className='footer-serviceDesc'
                      to='/service/metal-injection-molding-services'>
                      Metal Injection Molding
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <div className='footer-apps col-lg-3 col-md-3 col-sm-3 col-xs-12'>
              <p className='footer-appHead'> Applications </p>
              <div className='row app-row'>
                <div className='column1 col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                  <p>
                    <Link
                      className='footer-appDesc'
                      to='/industry/automotive-component-manufacturing'>
                      Automotive, Transportation
                    </Link>
                  </p>
                  <p>
                    <Link
                      className='footer-appDesc'
                      to='/industry/aerospace-and-defense-component-manufacturing'>
                      Defense, Aerospace
                    </Link>
                  </p>
                  <p>
                    <Link
                      className='footer-appDesc'
                      to='/industry/industrial-machinery-component-manufacturing'>
                      Industrial Machinery
                    </Link>
                  </p>
                  <p>
                    <Link
                      className='footer-appDesc'
                      to='/industry/hardware-startups-component-manufacturing'>
                      Hardware Startups
                    </Link>
                  </p>
                </div>
                <div className='column2 col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                  <p>
                    <Link
                      className='footer-appDesc'
                      to='/industry/consumer-durables'>
                      Consumer Durables
                    </Link>
                  </p>
                  <p>
                    <Link
                      className='footer-appDesc'
                      to='/industry/telecommunication-equipment-manufacturing'>
                      Telecommunication
                    </Link>
                  </p>
                  <p>
                    <Link
                      className='footer-appDesc'
                      to='/industry/medical-equipment-and-devices-manufacturing'>
                      Healthcare, Medical Devices
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-terms'>
          <ul>
            <li>
              <a
                href='https://www.custiv.com/pdfs/Custiv%20Terms%20of%20Service.pdf'
                target='_blank'>
                Terms & Conditions
              </a>
            </li>
            <li>
              <a
                href='https://www.custiv.com/pdfs/Custiv%20Privacy%20Policy.pdf'
                target='_blank'>
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href='https://www.custiv.com/pdfs/Custiv%20Shipping%20and%20Delivery%20Policy.pdf'
                target='_blank'>
                Shipping and Delivery
              </a>
            </li>
            <li>
              <a
                href='https://www.custiv.com/pdfs/Custiv%20Refund%20and%20Cancellation%20Policy.pdf'
                target='_blank'>
                Refunds and Cancellations
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
