import React, { useRef, useState, useEffect } from "react";
import { Link } from "gatsby";
import { motion, useCycle } from "framer-motion";
import { MenuToggle } from "./MenuToggle";
import { useDimensions } from "../utils/use-dimension";

function sidebarVariant() {
  return {
    open: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    closed: {
      opacity: 0,
      transition: {
        delay: 0.1,
        duration: 0.3,
        type: "spring",
      },
    },
  };
}

export const MobileMenu = ({ navRef }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);

  return (
    <motion.div initial={false} animate={isOpen ? "open" : "closed"}>
      {isOpen && (
        <motion.div
          className="mobile-menu-background"
          variants={sidebarVariant()}
        >
          {isOpen && (
            <div className="mobile-menu">
              <motion.ul>
                <motion.li className="main-item">
                  <Link to="/about-us">About Us</Link>
                </motion.li>
                <motion.li className="main-item">
                  <Link to="/quality-assurance">Quality Assurance</Link>
                </motion.li>
                <motion.li className="main-item">
                  <Link to="/contact-us">Contact Us</Link>
                </motion.li>
                <motion.li>
                  <div className="item-list">
                    <div className="header">Services</div>
                    <div className="row serv-row">
                      <div className="column1 col-lg-12 col-md-12 col-sm-12 col-xs-6">
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/service/cnc-machining-services"
                          >
                            CNC Machining
                          </Link>
                        </p>
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/service/prototyping-services"
                          >
                            Prototyping
                          </Link>
                        </p>
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/service/metal-fabrication-services"
                          >
                            Fabrication
                          </Link>
                        </p>
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/service/plastic-injection-molding-services"
                          >
                            Plastic Injection Molding
                          </Link>
                        </p>
                      </div>
                      <div className="column2 col-lg-12 col-md-12 col-sm-12 col-xs-6">
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/service/casting-services"
                          >
                            Casting
                          </Link>
                        </p>
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/service/metal-stamping-services"
                          >
                            Stamping
                          </Link>
                        </p>
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/service/metal-forging-services"
                          >
                            Forging
                          </Link>
                        </p>
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/service/metal-injection-molding-services"
                          >
                            Metal Injection Molding
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </motion.li>
                <motion.li>
                  <div className="item-list">
                    <div className="header">Industries</div>
                    <div className="row app-row">
                      <div className="column1 col-lg-12 col-md-12 col-sm-12 col-xs-6">
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/industry/automotive-component-manufacturing"
                          >
                            Automotive
                          </Link>
                        </p>
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/industry/aerospace-and-defense-component-manufacturing"
                          >
                            Aerospace and Defense
                          </Link>
                        </p>
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/industry/industrial-machinery-component-manufacturing"
                          >
                            Industrial Machinery
                          </Link>
                        </p>
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/industry/hardware-startups-component-manufacturing"
                          >
                            Hardware Startups
                          </Link>
                        </p>
                      </div>
                      <div className="column2 col-lg-12 col-md-12 col-sm-12 col-xs-6">
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/industry/consumer-durables"
                          >
                            Consumer Durables
                          </Link>
                        </p>
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/industry/telecommunication-equipment-manufacturing"
                          >
                            Telecommunication
                          </Link>
                        </p>
                        <p>
                          <Link
                            className="footer-serviceDesc"
                            to="/industry/medical-equipment-and-devices-manufacturing"
                          >
                            Healthcare and Medical Devices
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </motion.li>
              </motion.ul>
              <div className="social-info ">
                <p className="social-head"> For Requirements: </p>
                <p className="social-desc">
                  <a href="mailto:rfq@custiv.com"> rfq@custiv.com </a>
                </p>

                <p className="social-head"> For Support: </p>
                <p className="social-desc">
                  <a href="mailto:support@custiv.com"> support@custiv.com </a>
                </p>

                <p className="social-head"> For Partners: </p>
                <p className="social-desc">
                  <a href="mailto:partner@custiv.com"> partner@custiv.com </a>
                </p>

                <div className="social-channels">
                  <ul className="social-media">
                    <li>
                      <a href="#" target="_blank">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </motion.div>
      )}
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.div>
  );
};
