import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { FaArrowAltCircleRight } from "react-icons/fa";
import Modal from "./modal";

function BrochureDownloadModal({ service, open, setOpen }) {
  const [userExists, setUserExists] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const { handleSubmit, register, errors } = useForm();
  const [loading, setLoading] = React.useState(false);

  const download = async () => {
    setLoading(true);
    const res = await axios.get(
      "https://custiv-public-files.s3.ap-south-1.amazonaws.com/Custiv_Capabilities_Machining%20Brochure.pdf",
      { responseType: "arraybuffer" }
    );
    console.log(res);
    const blob = new Blob([res.data], { type: "application/pdf" });
    const file = new File([blob], `${service} Brochure - Custiv 2021.pdf`);
    const url = URL.createObjectURL(file);
    var element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("download", `${service} Brochure - Custiv 2021.pdf`);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
    setLoading(false);
    setOpen(false);
  };

  const checkForUser = async () => {
    // check to see if the user exists
    try {
      const res = await axios.get("https://api.custiv.com/api/forms/users/me", {
        withCredentials: true,
      });
      if (res.status === 200) {
        setUserExists(true);
      } else {
        setUserExists(false);
      }
      setLoading(false);
    } catch (error) {}
  };

  React.useEffect(() => {
    checkForUser();
  }, []);

  const onSubmit = async (data) => {
    if (userExists) {
      await download();
      setOpen(false);
      return;
    }

    // send email to backend
    try {
      const res = await axios.post("https://api.custiv.com/api/forms/enquiry", {
        email: data.email,
        name: "",
        phone: "",
      });
    } catch (error) {}
    download();
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className='brochure-modal'>
        <p className='message'>
          Please enter your email ID below to download brochure
        </p>
        <p>
          <form className='brochure-form' onSubmit={handleSubmit(onSubmit)}>
            <div className='input-container'>
              <input
                name='email'
                type='email'
                placeholder='Email'
                ref={register({
                  required: "Please enter your email id",
                })}
              />
              {errors.email && (
                <div className='error'>{errors.email.message}</div>
              )}
            </div>
            <button type='submit' disabled={loading}>
              {loading ? (
                <>Loading...</>
              ) : (
                <>
                  Submit &nbsp; <FaArrowAltCircleRight />
                </>
              )}
            </button>
          </form>
        </p>
      </div>
    </Modal>
  );
}

export default BrochureDownloadModal;
