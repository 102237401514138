/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Orejime from "orejime";
import { useStaticQuery, graphql, withPrefix } from "gatsby";

import { orejimeConfig } from "../utils/orejime";

function SEO({ description, lang, meta, title, keywords }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  async function cookieConsent() {
    await Orejime.init(await orejimeConfig);

    const orejimeConsentObject = document.getElementsByClassName(
      "orejime-Notice-description"
    );
    if (orejimeConsentObject.length > 0) {
      orejimeConsentObject[0].innerHTML =
        "We use cookies to optimize your experience for this website.";
    }
  }

  useLayoutEffect(() => {
    cookieConsent();
  }, []);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description || "",
        },
        {
          name: `keywords`,
          content: keywords || "",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description || "",
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}>
      <script src='/bootstrap/js/bootstrap.min.js' />
      <script
        type='opt-in'
        data-type='application/javascript'
        data-name='google-tag-manager'
        src={withPrefix("/google-tagmanager/google-tagmanager.js")}></script>
      <script
        async
        src='https://www.googletagmanager.com/gtag/js?id=AW-611517409'></script>
      <script
        type='opt-in'
        data-type='application/javascript'
        data-name='google-tag-manager'
        src={withPrefix("/google-tagmanager/gtag.js")}></script>
      <link rel='preconnect' href='https://fonts.gstatic.com' />
      <link
        href='https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap'
        rel='stylesheet'></link>
      <script
        src='https://cdnjs.cloudflare.com/ajax/libs/odometer.js/0.4.7/odometer.min.js'
        integrity='sha512-v3fZyWIk7kh9yGNQZf1SnSjIxjAKsYbg6UQ+B+QxAZqJQLrN3jMjrdNwcxV6tis6S0s1xyVDZrDz9UoRLfRpWw=='
        crossOrigin='anonymous'></script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  keywords: "",
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  keywords: PropTypes.string,
};

export default SEO;
