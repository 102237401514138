import { motion } from "framer-motion";
import { Link } from "gatsby";
import React, { useRef, useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import { useWindowScroll } from "react-use";
import BrochureDownloadModal from "../components/BrochureDownloadModal";
import { MobileMenu } from "../components/MobileMenu";

function Header() {
  const navRef = useRef(null);
  const { x, y } = useWindowScroll();
  const [brochureModalOpen, setBrochureModalOpen] = useState(false);
  function setDropdownContentTop(top) {
    const elems = document.getElementsByClassName("dropdown-content");
    for (const elem of elems) {
      elem.style.top = `${top}px`;
    }
  }

  React.useEffect(() => {
    if (y < 80) {
      navRef.current.style.height = "90px";
      setDropdownContentTop(90);
    }
    if (y > 100) {
      navRef.current.style.height = "60px";
      setDropdownContentTop(60);
    }
  }, [y]);

  return (
    <motion.nav className='navbar' ref={navRef} key='navbar'>
      <BrochureDownloadModal
        open={brochureModalOpen}
        setOpen={setBrochureModalOpen}
      />
      <div className='logo pr-1'>
        <Link to='/'>
          <img
            src={
              "https://custiv-website-assets-production.s3.ap-south-1.amazonaws.com/a13c14ff08/small_Custiv%20Logo.png"
            }
            alt='Custiv Logo'
          />
        </Link>
      </div>

      <div className='small-icons ml-auto p-0'>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarToggleExternalContent'
          aria-controls='navbarToggleExternalContent'
          aria-expanded='false'
          aria-label='Toggle navigation'>
          <span>
            <i className='fas fa-bars'></i>
          </span>
        </button>
      </div>

      <ul className='navmenu ml-auto'>
        <li className='mega-dropdown'>
          <a className='main dropbtn' href='#'>
            Services <FaCaretDown />
          </a>
          <div className='dropdown-content' id='myDropdown'>
            <div className='row all-appli'>
              <div className='drpdwn-appli-info'>
                <p className='drpdwn-appli-head'> Services </p>
                <p className='drpdwn-appli-desc'>
                  Custiv is a one stop partner to all Industrial segments,
                  helping companies transfer their manufacturing value chain
                  with complete manufacturing and engineering solutions - from
                  Prototyping to Production.
                </p>
              </div>

              <div className='row appli-type-row'>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/service/cnc-machining-services'
                      className='appli-type-head'>
                      CNC Machining
                    </Link>
                  </p>
                  <p className='appli-type-desc'>
                    CNC Turning, CNC Milling, 5 Axis Machining, Wire EDM,
                    Plastic Machining
                  </p>
                </div>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/service/prototyping-services'
                      className='appli-type-head'>
                      Prototyping
                    </Link>
                  </p>
                  <p className='appli-type-desc'>
                    DMLS, Vacuum Casting, Prototype Machining
                  </p>
                </div>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/service/metal-fabrication-services'
                      className='appli-type-head'>
                      Fabrication
                    </Link>
                  </p>
                  <p className='appli-type-desc'>
                    Laser Cutting, Welding, Bending, Tubular Fabrication
                  </p>
                </div>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/service/plastic-injection-molding-services'
                      className='appli-type-head'>
                      Plastic Injection Molding
                    </Link>
                  </p>
                  <p className='appli-type-desc'>
                    Mold Making, Prototype Tooling, Production Tooling
                  </p>
                </div>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/service/casting-services'
                      className='appli-type-head'>
                      Casting
                    </Link>
                  </p>
                  <p className='appli-type-desc'>
                    Die Casting, Investment Casting, Sand Casting
                  </p>
                </div>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/service/metal-stamping-services'
                      className='appli-type-head'>
                      Stamping
                    </Link>
                  </p>
                  <p className='appli-type-desc'>
                    Prototype Stamping, Progressive Die Stamping, Deep Drawn Die
                    Stamping
                  </p>
                </div>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/service/metal-forging-services'
                      className='appli-type-head'>
                      Forging
                    </Link>
                  </p>
                  <p className='appli-type-desc'>
                    Open Die Forging, Closed Die Forging, Roll Forging
                  </p>
                </div>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/service/metal-injection-molding-services'
                      className='appli-type-head'>
                      Metal Injection Molding
                    </Link>
                  </p>
                  <p className='appli-type-desc'></p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className='mega-dropdown'>
          <a className='main dropbtn' href='#'>
            Company <FaCaretDown />
          </a>
          <div className='dropdown-content' id='myDropdown'>
            <div className='row all-appli'>
              <div className='drpdwn-appli-info'>
                <p className='drpdwn-appli-head'> Company </p>
                <p className='drpdwn-appli-desc'>
                  Realize your industry specific custom manufacturing
                  requirements with our tailored solutions for each industry
                  without compromising quality.
                </p>
              </div>

              <div className='row appli-type-row'>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link to='/about-us' className='appli-type-head'>
                      About Us
                    </Link>
                  </p>
                  <p className='appli-type-desc'>
                    Learn more about our journey
                  </p>
                </div>

                <div className='drpdwn-appli-type'>
                  <p>
                    <Link to='/partner-network' className='appli-type-head'>
                      Partner Network
                    </Link>
                  </p>
                  <p className='appli-type-desc'>
                    Learn more about joining our partner network.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className='mega-dropdown'>
          <a className='main dropbtn' href='#'>
            Applications <FaCaretDown />
          </a>
          <div className='dropdown-content' id='myDropdown'>
            <div className='row all-appli'>
              <div className='drpdwn-appli-info'>
                <p className='drpdwn-appli-head'> Applications </p>
                <p className='drpdwn-appli-desc'>
                  Realize your industry specific custom manufacturing
                  requirements with our tailored solutions for each industry
                  without compromising quality.
                </p>
              </div>

              <div className='row appli-type-row'>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/industry/aerospace-and-defense-component-manufacturing'
                      className='appli-type-head'>
                      Aerospace and Defense
                    </Link>
                  </p>
                  <p className='appli-type-desc'> </p>
                </div>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/industry/telecommunication-equipment-manufacturing'
                      className='appli-type-head'>
                      Telecommunication
                    </Link>
                  </p>
                  <p className='appli-type-desc'> </p>
                </div>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/industry/industrial-machinery-component-manufacturing'
                      className='appli-type-head'>
                      Industrial Machinery
                    </Link>
                  </p>
                  <p className='appli-type-desc'> </p>
                </div>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/industry/consumer-durables'
                      className='appli-type-head'>
                      Consumer Durables
                    </Link>
                  </p>
                  <p className='appli-type-desc'> </p>
                </div>

                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/industry/medical-equipment-and-devices-manufacturing'
                      className='appli-type-head'>
                      Healthcare and Medical Devices
                    </Link>
                  </p>
                  <p className='appli-type-desc'> </p>
                </div>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/industry/automotive-component-manufacturing'
                      className='appli-type-head'>
                      Automotive and Transportation
                    </Link>
                  </p>
                  <p className='appli-type-desc'> </p>
                </div>
                <div className='drpdwn-appli-type'>
                  <p>
                    <Link
                      to='/industry/hardware-startups-component-manufacturing'
                      className='appli-type-head'>
                      Hardware Startups
                    </Link>
                  </p>
                  <p className='appli-type-desc'> </p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <Link to='/quality-assurance' className='main'>
            Quality Assurance
          </Link>
        </li>
        {/* <li>
          <Link to='/contact-us' className='main'>
            Contact Us
          </Link>
        </li> */}
        <li>
          <Link to='/blog' className='main'>
            Blogs
          </Link>
        </li>

        <li>
          <motion.a
            href='/contact-us'
            className='quote-link'
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}>
            Contact Us
          </motion.a>
        </li>
      </ul>
      <div className='mobile-nav'>
        <MobileMenu navRef={navRef} />
      </div>
    </motion.nav>
  );
}

export default Header;
