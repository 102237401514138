import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Header from "./header";
import Footer from "./footer";

import "../../static/css/style.css";
import "../../static/css/additional.css";
import "../../static/css/animate.css";
import "../../static/css/responsive.css";
import "../../static/bootstrap/css/bootstrap.min.css";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
