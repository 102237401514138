export const fadeIn = {
  show: {
    opacity: 1,
    transition: {
      delay: 0.1,
      duration: 0.4,
    },
  },
  hidden: {
    opacity: 0,
  },
};

export const fadeInUp = {
  show: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 30,
  },
};

export function fadeInFromBottomWithDelay(delay) {
  return {
    show: {
      opacity: 1,
      transition: {
        delay: delay,
        duration: 0.2,
      },
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: 30,
    },
  };
}

export function fadeInFromTopWithDelay(delay) {
  return {
    show: {
      opacity: 1,
      transition: {
        delay: delay,
        duration: 0.5,
      },
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: -30,
    },
  };
}

export function fadeInFromLeftWithDelay(delay) {
  return {
    show: {
      opacity: 1,
      transition: {
        delay: delay,
        duration: 0.5,
      },
      x: 0,
    },
    hidden: {
      opacity: 0,
      x: -30,
    },
  };
}

export function fadeInFromRightWithDelay(delay) {
  return {
    show: {
      opacity: 1,
      transition: {
        delay: delay,
        duration: 0.5,
      },
      x: 0,
    },
    hidden: {
      opacity: 0,
      x: 30,
    },
  };
}

export const zoomIn = {
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
  hidden: {
    opacity: 0,
    scale: 0.8,
  },
};

export function zoomInWithDelay(delay) {
  return {
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        // duration: 1.5,
        delay: delay,
        // type: "spring",
        bounce: 0.9,
        mass: 0.8,
        velocity: 1.5,
        damping: 4,
      },
    },
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
  };
}

export function zoomOutWithDelay(delay) {
  return {
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        delay: delay,
        // type: "spring",
        // bounce: 0.9,
        // mass: 0.8,
        // velocity: 1.5,
        // damping: 4,
      },
    },
    hidden: {
      opacity: 0,
      scale: 1.05,
    },
  };
}

export const staggerChildren = {
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
      delayChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
  },
};
