export const orejimeConfig = {
  appElement: "#___gatsby",
  cookieName: "orejime",
  cookieExpiresAfterDays: 365,
  cookieDomain: "custiv.com",
  privacyPolicy: "https://www.custiv.com/pdfs/Custiv%20Privacy%20Policy.pdf",
  default: true,
  mustConsent: false,
  mustNotice: false,
  lang: "en",
  logo: { src: "/images/logo-black.svg", alt: "Custiv" },
  debug: true,
  translations: {
    en: {
      consentModal: {
        description:
          "We use cookies to improve your experience on our website. ",
      },
      purposes: {
        necessary: "Necessary",
        preferences: "Preferences",
        analytics: "Analytics",
      },
      categories: {
        necessary: {
          description:
            "Cookies that are necessary for the website's functionality.",
        },
        preferences: {
          description:
            "Cookies that enhance your site experience based on your preferences.",
        },
        analytics: {
          description: "Cookies used for analytics.",
        },
      },
    },
  },

  apps: [
    {
      name: "custiv",
      title: "Custiv",
      cookies: [/^custiv/],

      purposes: ["necessary"],
      callback: function (consent, app) {
        console.log(
          "User consent for app " + app.name + ": consent=" + consent
        );
      },
      required: true,
      optOut: false,
      default: true,
      onlyOnce: true,
    },
    {
      name: "zoho",
      title: "Zoho",
      cookies: [/^zab/],

      purposes: ["preferences"],
      callback: function (consent, app) {
        console.log(
          "User consent for app " + app.name + ": consent=" + consent
        );
      },
      required: false,
      optOut: false,
      default: true,
      onlyOnce: true,
    },
    {
      name: "google-tag-manager",

      title: "Google Tag Manager",
      cookies: [/^_gat/, /^_ga/, /^_gid/, /^_gcl/],

      purposes: ["analytics"],
      callback: function (consent, app) {
        console.log(
          "User consent for app " + app.name + ": consent=" + consent
        );
      },
      required: false,
      optOut: false,
      default: true,
      onlyOnce: true,
    },
  ],
  categories: [
    {
      name: "necessary",
      title: "Necessary",
      apps: ["custiv"],
    },
    {
      name: "preferences",
      title: "Preferences",
      apps: ["zoho"],
    },
    {
      name: "analytics",
      title: "Analytics",
      apps: ["google-tag-manager"],
    },
  ],
};
